import OfferCards from "components/offers/offerCards";
import React from "react";

import Layout from "components/layout";
import { ls } from "utility/helper";
import { Link } from "gatsby";

const Offers = ({ location }) => {
  const { isLoggedIn = false } = ls("profile");

  return (
    <Layout title="إشتراكات">
      <OfferCards
        to={`${location.pathname}/App/dashboard#subscriptions#flexy`}
      />

      <h2 id="pay">طرق الدفع</h2>
      <h3>عن طريق بريدي موب BaridiMob أو CCP</h3>
      <ul>
        {!isLoggedIn ? (
          <li>
            <Link className="button primary" to="/Signin">
              سجل حساب مجاني أو سجل الدخول{" "}
            </Link>
          </li>
        ) : null}
        <li>
          <p>
            ادفع عن طريق تطبيق بريدي موب BaridiMob باستعمال رقم الحساب RIP:
            00799999001249252827
          </p>
          <Link className="link" to="#mob">
            كيف أدفع باستعمال التطبيق؟
          </Link>
        </li>
        <li>
          <p>
            أو باسعمال حوالة بريدية رقم الحساب 0012492528clé27 و الإسم و اللقب:
            بن أحمد محمد أمين(Benahmed mohammed amine)
          </p>
        </li>
        <li>
          <Link to="/App/dashboard/#subscriptions">أرسل صورة الوصل </Link>
        </li>
      </ul>
      <h3 id="mob">طريقة الدفع عن طريق بريدي موب BaridiMob</h3>
      <ul>
        <li>أدخل إلى تطبيق بريدي موب </li>
        <li>إضغط على زر تحويل Virements</li>
        <li>ثم إظغط على زر التحويل إلى حساب آخر Virement vers autre compte</li>
        <li>
          حدد مصدر التحويل source de virment ثم أكتب رقم الحساب :
          00799999001249252827 في خانة حساب المستفيد compte RIP du bénéficiaire
          و أخيرا أكتب قيمة التحويل Le montant
        </li>
        <li>إضغط على زر الإستمرار continuer</li>
        <li>
          قم بتأكيد التحويل عن طريق الضغط على زر Obtenez le code de confirmation
        </li>
        <li>أكتب الرقم الذي بعث لك بالرسالة القصيرة SMS</li>
        <li>و أخيرا إضغط على زر الإستمرار continuer</li>
        <li> احفظ صورة الوصل</li>
      </ul>
    </Layout>
  );
};

export default Offers;
